//
// react-table.scss
//

.react-table {
    th,
    td {
        white-space: nowrap;
    }
    th {
        //padding-left: $table-cell-padding-y;
        //padding-right: 30px;
        // &.id, &.age{
        //     width: 40px;
        // }
        //line-height: initial;
    }

    .sortable {
        position: relative;
        cursor: pointer;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            background: transparent url(../../../images/icons/sort_both.svg) center/0.6em auto no-repeat;
            width: 25px;
            position: absolute;
            height: 22px;
            opacity: 0.5;
        }
        &.sorting_asc {
            &:after {
                background: transparent url(../../../images/icons/sort_asc.svg) center/0.6em auto no-repeat;
                opacity: 1;
            }
        }
        &.sorting_desc {
            &:after {
                background: transparent url(../../../images/icons/sort_desc.svg) center/0.6em auto no-repeat;
                opacity: 1;
            }
        }
    }
    .table-dropdown {
        .dropdown-toggle {
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .dropdown-menu {
            width: 100%;
        }
    }
}
