.green {
    color: #29b349;
}
.light-green {
    color: #def2e9;
}
.red {
    color: #e35953;
}
.light-red {
    color: #faecec;
}
.yellow {
    color: #e7a30f;
}
.light-yellow {
    color: #fae7bf;
}
.top-border {
    border-top: 1px solid #dee2e6;
}
.recording-iframe {
    width: 100%;
    height: 640px;
}
