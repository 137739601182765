//Analytics page - email block UI
.analytics-data {
    @include custom-filter-style(200px, 185px);
}
//edit record and setting - tag block UI
.edit-record-and-settings-page {
    @include custom-filter-style(115px, 170px);
}

/*custom-card-panel
**fixed height & scroll
*/
.digital-assets,
.content-Panel {
    .custom-card-height {
        height: 550px;
        @include custom-height-and-scroll(550px);
    }
}

// scroll on impact sort modal
.impact-sort-modal {
    @include custom-height-and-scroll(600px);
}
.edit-profile-information {
    .custom-width-switch {
        min-width: 86px;
        width: auto;
    }
}
.analytics-dashboard-page {
    .card-header {
        border: 0;
        .align-items-center {
            justify-content: flex-end !important;
        }
    }
    .dropdown-card {
        .card-header {
            border-bottom: #dee2e6 1px solid;
            .align-items-center {
                justify-content: space-between !important;
            }
        }
    }
}
//hide iframe--> getting issue when changing a single code of line in browser
iframe[style] {
    display: none !important;
    &.video-block {
        display: block !important;
        width: inherit;
        height: inherit;
        background-color: $black;
    }
}
video {
    background-color: $black;
}

//Custom svg size
.view-sites-table,
.view-reports-table,
.report-data {
    .custom-svg svg {
        width: 23px;
    }
}
//scroll on donor tab
.donors-tab {
    @include custom-height-and-scroll(1000px);
    .border-bottom3 {
        border-bottom: 3px solid #dee2e6;
    }
    .checkbox {
        width: 3%;
    }
    .donor-list {
        width: 97%;
    }
}
//custom header UI
.custom-header {
    @include custom-header();
}
//Border removed from datepicker
.edit-record-and-settings-page {
    .react-datepicker__input-container {
        input.form-control-light {
            border: none;
        }
    }
}
