.filter-wrapper {
    .dropdown-menu.show {
        inset: 0px 0px auto auto !important;
        transform: translate3d(0px, 34px, 0px) !important;
    }
    .mdi-filter-menu-outline,
    .mdi-filter-menu {
        margin-right: 10px;
    }
    //css for applied filter
    &.filter-applied {
        .btn-filter {
            border-color: $orange;
            background-color: $orange;
            color: $white;
        }
        .mdi-filter-menu {
            color: $white;
        }
    }
}
.card-header {
    border-bottom: $border-color 1px solid;
    .input-group {
        .input-group-text {
            border: 0;
            background: #fff;
            padding: 0;
            font-size: 17px;
        }
        .form-control {
            border: 0;
        }
    }

    .dropdown-card {
        .dropdown-menu {
            min-width: 320px;
            [class*='-control'] {
                border: 1px $border-color solid;
                font-size: 0.8125rem;
                padding-right: 8px;
                &.is-invalid {
                    border-color: #e7706a;
                }
            }
            ::placeholder {
                color: #95aac9;
            }
            .list-group {
                .list-group-item {
                    padding-inline: 0;
                    border-bottom-width: 1px;
                }
            }
        }
    }
    .select__indicators {
        display: none;
    }
    [class*='-container'] {
        *:focus,
        &:focus,
        *:focus-visible,
        &:focus-visible {
            outline: none;
            box-shadow: none;
            // border: 1px #dee2e6 solid;
        }
        [class*='-menu'] {
            min-width: 106px;
            margin-top: 0 !important;
            // border-top-right-radius: 0;
            // border-top-left-radius: 0;
            width: 100%;
        }
        [class*='-indicatorContainer'] {
            &.react-select__clear-indicator {
                display: none;
            }
            padding: 0;
            svg {
                width: 14px;
                height: 14px;
            }
        }
        [class*='-placeholder'] {
            position: static;
            transform: none;
            font-size: 0.8125rem;
            color: $gray-500;
        }
        [class*='-control'] {
            border: 0;
            // min-width: 60px;
            box-shadow: none;
            min-height: auto;
            &:focus,
            &:focus-visible {
                outline: none;
                box-shadow: none;
                border: 1px gray solid;
            }
        }
        [class*='indicatorSeparator'] {
            width: 0;
        }
        [class*='option'] {
            padding: 3px 12px;
            background: none;
            color: #6c757d;
            font-size: 0.8125rem;
            cursor: pointer;
            white-space: nowrap;
            &:hover {
                background: none;
                color: rgb(39, 38, 38);
            }
        }
        [class*='month-option--selected'] span,
        [class*='year-option--selected'] span {
            padding-top: 1px;
            left: -2px;
        }
        [class*='-Input'] {
            padding: 0;
            margin: 0;
        }
    }
    [class*='-singleValue'] {
        position: static;
        transform: none;
        max-width: inherit;
        font-size: 0.8125rem;
    }
    [class*='-MenuList'] {
        overflow: inherit;
        max-height: auto;
        position: static;
    }
}
.btn-filter {
    background: #fff;
    border-color: #e3ebf6;
    box-shadow: none;
    color: #6c757d;
    &:hover {
        background: rgba($light, 0.7);
        border-color: #e3ebf6;
        color: rgb(39, 38, 38);
        box-shadow: none;
    }
    &:focus,
    &:active {
        background: rgba($light, 0.7);
        border-color: #e3ebf6;
        color: rgb(39, 38, 38);
        box-shadow: 0 0 0 0.15rem #dfe2e680;
    }
}
.filter-circle {
    display: inline-block;
    position: absolute;
    top: 7px;
    left: 20px;
    border-radius: 50%;
    height: 6px;
    width: 6px;
    background-color: #fa5c7c;
}
.link-funds tr[disabled] {
    pointer-events: none !important;
    background: #eff4f5;
    opacity: 0.5;
}
.multiple-sites tr[disabled] {
    pointer-events: none !important;
    background: #eff4f5;
    opacity: 0.5;
}
.multiple-reports tr[disabled] {
    pointer-events: none !important;
    background: #eff4f5;
    opacity: 0.5;
}

.gutter-5 {
    margin-inline: -5px;
    div[class*='col'] {
        padding-inline: 5px;
    }
}

.filter-tag {
    @include custom-filter-style(62px, 100px);
    .custom-filter-style-block {
        .custom-input {
            padding: 0.1rem 0.6rem;
            margin: 4px;
        }
        .list {
            margin: 0.3rem 0 0 0.4rem;
            .value {
                font-size: 85%;
                padding: 2px;
                padding-left: 8px;
            }
            .cross-icon {
                padding-left: 4px;
                padding-right: 4px;
            }
        }
    }
}
// bold and color change on already applied filter
.react-select {
    .fw-bolder {
        font-weight: normal !important;
    }
    .fw-bolder.react-select__single-value,
    .react-select__single-value {
        font-weight: 500 !important;
        color: #2b2f31 !important;
    }
}
