.multi-step {
    .form-floating {
        margin: 0.5rem 1rem 0.5rem 1rem;
    }
    p,
    .row {
        padding: 1.7rem 1rem 1.7rem 1rem;
    }
}
.multi-step,
.multi-step * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.multi-step ::after,
.multi-step ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.multi-step {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .modal-title {
        text-align: center;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        font-size: 0.8rem;
    }
    .modal-steps {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #dee2e6;
        .step {
            width: 100%;
            position: relative;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            pointer-events: none;
            &:before {
                width: 100%;
            }
            &:after {
                width: 0%;
                left: 0;
                -webkit-transition: 0.2s linear;
                -o-transition: 0.2s linear;
                transition: 0.2s linear;
            }
            &.current {
                &:after {
                    width: calc(50% - 10px);
                    -webkit-transition: 0.2s linear;
                    -o-transition: 0.2s linear;
                    transition: 0.2s linear;
                }
                &:first-child {
                    &:after {
                        width: 0% !important;
                        -webkit-transition: 0.2s linear;
                        -o-transition: 0.2s linear;
                        transition: 0.2s linear;
                    }
                }
                .dot {
                    &:before {
                        background-color: #eaeaea;
                    }
                }
            }
            .dot {
                height: 20px;
                width: 20px;
                -webkit-border-radius: 20px;
                border-radius: 20px;
                display: inline-block;
                position: relative;
                top: 0.125em;
                z-index: 2;
                margin: auto;
                border: 1px solid;
                border-color: #eaeaea;
                background-color: #eaeaea;
                &:before {
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    width: 20px;
                    height: 20px;
                    -webkit-transform: scale(0.75);
                    -ms-transform: scale(0.75);
                    transform: scale(0.75);
                    -webkit-border-radius: 20px;
                    border-radius: 20px;
                }
            }
            .label {
                font-size: 12px;
                display: block;
                margin: 0.5em 0;
                text-align: center;
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 100%;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
            }
            &.completed {
                .dot {
                    &:before {
                        background-color: #39c0ba;
                    }
                }
            }
            &.skipped {
                .dot {
                    &:before {
                        background-color: #ffc107;
                    }
                }
            }
        }
    }
    .modal-body {
        .step-content-container {
            position: relative;
            height: 100%;
            .step-content {
                display: none;
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                .content-inner {
                    position: relative;
                }
                &.active {
                    display: inline-block;
                }
            }
        }
    }
    .modal-footer {
        .btn-prev {
            margin-right: auto;
        }
        .btn-skip {
            display: none;
            &.skippable {
                display: block;
            }
        }
    }
}
.multi-step .modal-steps .step:after,
.multi-step .modal-steps .step:before {
    position: absolute;
    border: 1px solid;
    height: 1px;
    display: block;
    z-index: 1;
    top: 11px;
    content: '';
    border-color: #eaeaea;
}
.multi-step .modal-steps .step:first-child:after,
.multi-step .modal-steps .step:first-child:before {
    width: 50% !important;
    left: unset;
    right: unset;
    -webkit-transform: translateX(51%);
    -ms-transform: translateX(51%);
    transform: translateX(51%);
}
.multi-step .modal-steps .step:last-child:after,
.multi-step .modal-steps .step:last-child:before {
    width: 50% !important;
    left: 0;
}
.multi-step .modal-steps .step.completed:after,
.multi-step .modal-steps .step.skipped:after {
    width: 100%;
    -webkit-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
}
.multi-step .modal-footer .disabled,
.multi-step .modal-footer :disabled {
    cursor: not-allowed;
}
.flex-center {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    p {
        margin: 0;
    }
    ul {
        text-align: center;
        li {
            margin-bottom: 1rem;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
.multi-step .modal-steps .step.completed .dot,
.multi-step .modal-steps .step.completed:after,
.multi-step .modal-steps .step.current .dot,
.multi-step .modal-steps .step.current:after {
    border-color: #39c0ba;
}
.multi-step .modal-steps .step.skipped .dot,
.multi-step .modal-steps .step.skipped:after {
    border-color: #ffc107;
}
