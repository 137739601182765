//
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th,
    td {
        vertical-align: middle !important;
    }
}
//Custom min-height on tables
.table-responsive {
    height: 700px;
    &.table-auto-height {
        min-height: 140px !important;
        height: auto !important;
    }
    // icon size larger css for table col
    .icon-large {
        font-size: larger;
    }
    //scroll on table
    &.height-fixed {
        height: auto;
        tbody {
            height: 700px;
            table-layout: auto;
            display: block;
            overflow-y: overlay;
            overflow-x: hidden;
        }

        thead,
        tbody tr {
            display: table;
            width: 100%;
            table-layout: auto;
        }
    }
}
//table height on modals
.modal-body {
    .table-responsive {
        height: 600px;
        //scroll on table
        &.height-fixed {
            tbody {
                height: 600px;
            }
        }
        &.table-resize {
            margin-bottom: 2rem;
            table {
                margin-bottom: 0;
            }
        }
    }
}

// Tables fluid
.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}
