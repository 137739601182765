.modal.show .modal-dialog.preview {
    transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transition: none;
}
.previewOnly {
    div[cpid] {
        transform: scale(0.9) translate(-5%, 0);
        -ms-transform: scale(0.9) translate(-5%, 0);
        -webkit-transform: scale(0.9) translate(-5%, 0);
        .assetseditor,
        .panelHeader,
        .inline-textEditor {
            pointer-events: none;
        }
    }
}
