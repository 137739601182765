//
// progress.scss
//

// Custom height

// Progress height small
.progress-sm {
    height: 5px;
}

// Progress height medium
.progress-md {
    height: 8px;
}

// Progress height large
.progress-lg {
    height: 12px;
}

// Progress height extra large
.progress-xl {
    height: 15px;
}

.custom-progressSize {
    width: 200px;
    margin-top: 20px;
}
@include media-breakpoint-only(xs) {
    .custom-progressSize {
        margin-top: 0px;
    }
}
