.tox.tox-tinymce-inline .tox-editor-header {
    border-color: $white !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    box-shadow: 0px 2px 6px 0px rgb(51 62 68 / 28%) !important;
    margin-top: -2px;
    .tox-toolbar__group {
        padding: 0;
        &:before {
            content: 'Text: ';
            margin: 0 10px;
        }
        button:last-child {
            min-width: 35px;
        }
        .tox-tbtn--select {
            margin: 0;
        }
        .tox-tbtn {
            margin: 0;
            border-radius: 0;
            width: 40px;
            height: 32px;
            &:hover {
                background-color: $primary;
                color: $white;
                svg {
                    fill: $white;
                }
            }
            &.tox-tbtn--disabled {
                background-color: #e2e3e3;
                color: #797c7f;
                svg {
                    fill: #797c7f;
                }
            }
            &:last-child {
                background-color: $white;
                cursor: auto;
                &:hover {
                    background-color: $white;
                }
                color: #008f8a;
            }
        }
    }
}
